import { template as template_411324dba99e47b0bb3b4e0035799866 } from "@ember/template-compiler";
const FKControlMenuContainer = template_411324dba99e47b0bb3b4e0035799866(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
