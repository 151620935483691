import { template as template_51b9568454524c328241b54de075a9cf } from "@ember/template-compiler";
const FKLabel = template_51b9568454524c328241b54de075a9cf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
